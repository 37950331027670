import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import { PngIcon } from "../../common/PngIcon";
import { useState } from "react";
import RequestAccessModal from "../RequestAccessModal";

import {
  FooterSection,
  FooterContainer,
  Title,
  NavLink,
  ModalNavLink,
  LogoContainer,
  Para
} from "./styles";
import ConfirmationModal from "../ConfirmationModal";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
        style={{marginRight: "2.0rem"}}
      >
        <SvgIcon src={src} width="30px" height="30px" />
      </a>
    );
  };

  return (
    <FooterSection>
      <FooterContainer>
        <Row justify="space-between">
          <Col order={0} lg={9} md={9} sm={24} xs={24}>
            <NavLink to="/">
              <LogoContainer>
                <PngIcon
                  className="footer-logo"
                  src="logo-colored.png"
                  aria-label="homepage"
                  width="150px"
                  height="auto"
                />
              </LogoContainer>
            </NavLink>
            <Para style={{ paddingTop: "4.0rem" }}>{t("FooterText")}</Para>
          </Col>
          <Col className="social-col" order={2} lg={24} md={24} sm={24} xs={24}>
            <Row className="social" align="middle" justify="start">
              <SocialLink
                href="https://www.linkedin.com/company/weavii"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://instagram.com/weaviihq"
                src="instagram.svg"
              />
            </Row>
          </Col>
          <Col order={1} lg={7} md={7} sm={24} xs={24}>
            <Row>
              <Col className="support" lg={14} md={12} sm={12} xs={12}>
                <Title>{t("Support")}</Title>
                <NavLink to="/contact">{t("Contact Us")}</NavLink>
                <NavLink to="/feedback">{t("Feedback")}</NavLink>
              </Col>
              <Col lg={10} md={12} sm={12} xs={12}>
                <Title>{t("Our Company")}</Title>
                <ModalNavLink onClick={showModal}>{t("Request Access")}</ModalNavLink>
                <NavLink to="/signin">{t("Sign In")}</NavLink>
                <NavLink to="/imprint">{t("Imprint")}</NavLink>
                <RequestAccessModal open={open} setOpen={setOpen} setOpenConfirmation={setOpenConfirmation} />
                <ConfirmationModal open={openConfirmation} setOpen={setOpenConfirmation} title="Request sent!"
                  text="Thank you for your interest in joining Weavii! We're excited to have you onboard.
                  You will receive an email from us when your request has been approved." />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="bottom-content" justify="space-between">
          <Col lg={17} md={16} sm={24} xs={24}>
            <Para>{t("RightsText")}</Para>
          </Col>
          <Col lg={7} md={8} sm={24} xs={24}>
            <Row justify="end">
              <Col lg={7} md={9} sm={12} xs={12} className="term-of-use">
                <NavLink to="/">{t("Term of Use")}</NavLink>
              </Col>
              <Col lg={8} md={9} sm={12} xs={12} offset={2}>
                <NavLink style={{textAlign: "left"}} to="/privacy">{t("Privacy Policy")}</NavLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </FooterContainer>
    </FooterSection>
  );
};

export default withTranslation()(Footer);
